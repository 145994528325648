import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Masthead from '../components/masthead';
import SingleCol from '../components/single-col';
import HomeCarousel from '../components/home-carousel';
import Tiles from '../components/tiles';
import SectionTestimony from '../components/testimonial';

const StyledHome = styled.div`
	.team {
		background-color: ${(p) => p.theme.color.blue};
		background-image: url('/img/ECTeam.png');
		background-size: auto 70%;
		background-repeat: no-repeat;
		background-position: center bottom;
		padding: 10rem 4rem 5rem;
		@media all and (min-width: 769px) {
			background-position: left bottom;
		}
		.text-container {
			width: 50%;
			margin-left: auto;
			h2 {
				color: #fff;
				font-family: ${(p) => p.theme.fonts.title};
				margin-bottom: 2rem;
				font-weight: 700;
			}
			.ec-button {
				background-color: ${(p) => p.theme.color.dark};
				&:hover {
					background-color: #2c2b2d;
				}
			}
		}
		@media all and (min-width: 1200px) {
			background-size: auto 75%;
			padding: 15rem 4rem 8rem;
		}
		@media all and (min-width: 1400px) {
			background-size: auto 80%;
			padding: 17rem 4rem 10rem;
		}
		@media all and (max-width: 900px) {
			.text-container {
				width: 45%;
			}
		}
		@media all and (max-width: 850px) {
			padding: 4rem;
			background-size: auto 78%;
		}
		@media all and (max-width: 768px) {
			padding: 2rem ${(p) => p.theme.gutter} 23rem;
			background-size: auto 65%;
			.text-container {
				width: 100%;
			}
		}
		@media all and (max-width: 540px) {
			padding: 2rem ${(p) => p.theme.gutter} 25rem;
			background-size: 100% auto;
		}
		@media all and (max-width: 500px) {
			padding: 2rem ${(p) => p.theme.gutterMobile} 23rem;
		}
		@media all and (max-width: 450px) {
			padding: 2rem ${(p) => p.theme.gutterMobile} 20rem;
		}
		@media all and (max-width: 380px) {
			padding: 2rem ${(p) => p.theme.gutterMobile} 17rem;
		}
	}

	.recovering {
		background-image: url('/img/recoveringdrivers_image.jpg');
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		padding: 5rem ${(p) => p.theme.gutter};
		.text-container {
			width: 50%;
			h2 {
				font-weight: 700;
				color: ${(p) => p.theme.color.dark};
			}
			hr {
				margin: 0.5rem 0 0.5rem;
			}
			p {
				font-family: ${(p) => p.theme.fonts.body};
				margin-bottom: 3rem;
			}
			.ec-button {
				background-color: ${(p) => p.theme.color.dark};
				&:hover {
					background-color: #2c2b2d;
				}
			}
		}
		@media all and (min-width: 1200px) {
			padding: 7rem ${(p) => p.theme.gutterWide};
		}
		@media all and (min-width: 1600px) {
			padding: 7rem ${(p) => p.theme.gutterXWide};
		}
		@media all and (max-width: 700px) {
			padding: 4rem ${(p) => p.theme.gutter} 18rem;
			background-image: url(/img/recoveringdrivers_image_mobile.jpg);
			background-position: center bottom;
			.text-container {
				width: 100%;
			}
		}

		@media all and (max-width: 500px) {
			padding: 2rem ${(p) => p.theme.gutterMobile} 14rem;
		}
	}

	.contact {
		background-color: ${(p) => p.theme.color.dark};
		padding: 3rem ${(p) => p.theme.gutter};
		@media all and (min-width: 1200px) {
			padding: 3rem ${(p) => p.theme.gutterWide};
		}
		@media all and (min-width: 1600px) {
			padding: 4rem ${(p) => p.theme.gutterXWide};
		}

		.contact-info {
			width: 25%;
			flex: none;
			.address {
				color: #fff;
			}
			.white-link {
				margin-left: 0.5rem;
			}
		}

		.map {
			width: 50%;
			flex: none;
			display: flex;
			align-items: center;
		}

		p {
			color: #fff;
			margin-bottom: 0.1rem;
			text-align: left;
		}

		.info-left {
			border-right: 2px solid #999999;
		}

		.info-right {
			margin-left: 2rem;
		}

		.company-name {
			font-weight: 700;
			font-family: ${(p) => p.theme.fonts.title};
			line-height: 1.2rem;
			margin-bottom: 1.25rem;
		}

		@media all and (min-width: 1600px) {
			.company-name {
				font-size: 1.5rem;
				line-height: 1.5rem;
			}
		}

		@media all and (max-width: 1000px) {
			padding: 3rem ${(p) => p.theme.gutter};
			.company-name {
				margin-bottom: 0.5rem;
			}
		}
		@media all and (max-width: 850px) {
			.columns {
				display: flex;
				flex-wrap: wrap;
			}
			.map {
				width: 100%;
			}
			.contact-info {
				margin-bottom: 1rem;
				width: 50%;
			}
		}
		@media all and (max-width: 768px) {
			.info-right {
				margin-left: 0;
			}
		}
		@media all and (max-width: 500px) {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
		@media all and (max-width: 450px) {
			.columns .contact-info {
				width: 100%;
			}
		}
	}
`;
export default ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<StyledHome>
			<Masthead
				title={content.banner_title}
				title2={content.banner_title2}
				description={content.banner_content}
				image={content.banner_image}
				page="home"
				button={{
					link: content.banner_button_goto,
					text: content.banner_button_text,
				}}
			/>
			<SingleCol
				title={content.section_1_title}
				content={content.section_1_content}
				button={content.section_1_button}
				page="home"
			/>
			<HomeCarousel images={content.slides} />
			<Tiles tiles={content.tiles} page="home" />

			<div className="team">
				<div className="text-container">
					<h2>{content.section_2_title}</h2>
					<Link to={content.section_2_button_goto} className="ec-button">
						{content.section_2_button_text}
					</Link>
				</div>
			</div>

			<SectionTestimony page="home" data={content.testimony} />

			<div className="section contact">
				<div className="columns">
					<div className="column contact-info">
						<p className="company-name">Edwards Insurance Barrie</p>

						<a
							className="address"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.google.com/maps/place/Edwards+Insurance+Brokers/@44.338483,-79.6994837,17z/data=!3m1!4b1!4m5!3m4!1s0x882abdad6482492b:0xb9d186bb6a71f0c0!8m2!3d44.338483!4d-79.697295?hl=en"
						>
							92 Caplan Avenue
							<br />
							Suite 220
							<br />
							Barrie, Ontario
							<br />
							L4N 9J2 Canada
							<br />
						</a>
						<br />
						<p>
							Phone:
							<a className="white-link" href="tel:17057197956">
								705.719.7956
							</a>
						</p>
						<p>
							Fax:
							<a className="white-link" href="tel:18665441535">
								866.544.1535
							</a>
						</p>
						<p>
							Toll Free:
							<a className="white-link" href="tel:18775224258">
								877.522.4258
							</a>
						</p>
					</div>
					<div className="column contact-info">
						<p className="company-name">Edwards Insurance Collingwood</p>

						<a
							className="address"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.google.com/maps/place/595+Cityview+Blvd,+Woodbridge,+ON+L4H+0C9/@43.8618855,-79.555191,19z/data=!3m1!4b1!4m5!3m4!1s0x882b28af6869cc03:0xc1a1e4da36512bf7!8m2!3d43.8618855!4d-79.5546438?hl=en"
						>
							1 First Street
							<br />
							Suite 220
							<br />
							Collingwood, Ontario
							<br />
							L9Y 1A1 Canada
							<br />
						</a>
						<br />
						<p>
							Phone:
							<a className="white-link" href="tel:+17057197956">
								705.719.7956
							</a>
						</p>
					</div>

					<div className="column map">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.google.com/maps/place/Edwards+Insurance+Brokers/@44.338483,-79.6994837,17z/data=!3m1!4b1!4m5!3m4!1s0x882abdad6482492b:0xb9d186bb6a71f0c0!8m2!3d44.338483!4d-79.697295?hl=en"
						>
							<img
								src={content.map}
								alt="Google Map of Edwards Insurance Brokers, Caplan Avenue, Barrie, ON"
							/>
						</a>
					</div>
				</div>
			</div>
		</StyledHome>
	);
};
