import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';

const StyledCarousel = styled.div`
	.home-slider {
		position: relative;
		.slider-control-centerright {
			position: unset !important;
			transform: none !important;
		}

		.info-frame {
			color: #fff;
			bottom: 7.6rem;
			position: absolute;
			width: 468px;
			right: 0;
			background-color: ${(p) => p.theme.color.blue};
			padding: 1.5rem 3rem;
			display: flex;
			align-items: center;
			h2 {
				font-size: 1.2rem;
				font-weight: 700;
				text-transform: uppercase;
				line-height: 1.5rem;
				font-family: ${(p) => p.theme.fonts.title};
			}
			p {
				margin: 0;
				font-size: 0.9rem;
				line-height: 1.3rem;
			}
			button {
				position: relative;
				&.arrow-left {
					left: -1rem;
				}
				&.arrow-right {
					right: -1rem;
				}
				img {
					height: 20px;
					width: 20px;
					max-width: none;
				}
			}
		}
	}
	@media all and (min-width: 1600px) {
		.home-slider .info-frame {
			width: 500px;
			h2 {
				font-size: 1.3rem;
				line-height: 1.7rem;
			}
			p {
				font-size: 1.1rem;
				line-height: 1.7rem;
			}
		}
	}
	@media all and (max-width: 768px) {
		.home-slider .info-frame {
			height: 9rem;
			padding: 3rem;
			width: 100%;
			bottom: 0;
			margin-top: -0.5rem;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;
			position: relative;
			button {
				bottom: 3rem;
			}
		}
	}
	@media all and (max-width: 500px) {
		.home-slider .info-frame {
			padding: 1.5rem 2.5rem;
			height: auto;
			button {
				bottom: 2rem;
				img {
					width: 15px;
					height: 15px;
				}
			}
		}
	}
`;

export default ({ images, id }) => {
	const [title, setTitle] = useState(images[0].title);
	const [description, setDescription] = useState(images[0].description);

	useEffect(() => {
		setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
	});

	const changeSlide = (current) => {
		setTitle(images[current].title);
		setDescription(images[current].description);
	};

	return (
		<StyledCarousel id={id}>
			<div className="home-slider">
				<Carousel
					afterSlide={(prevSlide) => {
						changeSlide(prevSlide);
					}}
					autoplayInterval={5000}
					wrapAround={true}
					autoplay={true}
					renderCenterLeftControls={null}
					renderBottomCenterControls={null}
					renderCenterRightControls={({ previousSlide, nextSlide }) => (
						<div className="info-frame">
							<button onClick={previousSlide} className="arrow-left">
								<img src="/img/arrow-left.svg" alt="arrow left" />
							</button>
							<div className="text-container">
								<h2 id="slider-title">{title}</h2>
								<p id="slider-description">{description}</p>
							</div>
							<button onClick={nextSlide} className="arrow-right">
								<img src="/img/arrow-right.svg" alt="arrow right" />
							</button>
						</div>
					)}
				>
					{images.map((image, index) => (
						<div key={index}>
							<img
								src={image.image}
								alt={image.description}
								className="img-responsive"
							/>
						</div>
					))}
				</Carousel>
			</div>
		</StyledCarousel>
	);
};
