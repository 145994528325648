import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HomeTemplate from '../templates/home-template';
import SEO from '../components/seo';

const HomePage = ({ data }) => {
	return (
		<Layout page="Home">
			<SEO title="Home" />
			<HomeTemplate data={data} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "home" } } }) {
			edges {
				node {
					frontmatter {
						testimony {
							author
							location
							content
						}
						slides {
							title
							description
							image
						}
						banner_title
						banner_title2
						banner_content
						banner_image
						banner_button_goto
						banner_button_text
						section_1_title
						section_1_content
						tiles {
							title
							content
							goto
						}
						section_2_title
						section_2_button_text
						section_2_button_goto
						map
					}
				}
			}
		}
	}
`;
